<template>
  <div>
    <Header class="fixed-title" :title="'EAM服务介绍'" :back="true"/>
    <div class="eam-box">
      <div class="title">服务介绍</div>
      <div class="box-items">
        <img  style="width:80%;margin-left:10%" src="@/assets/img/yz.png"/>
        <div class="fw-text">EAM能提供高度个性化和量身定制的服务，能提供非常多元化的服务，比客户更了解银行，比银行更懂客户，能够找到客户与银行沟通的痛点，架设一座通畅的桥梁。</div>
      </div>
      
      <div class="title">优势服务</div>
      <div class="box-items">
        <img  style="width:50%;margin-left:25%" src="@/assets/img/yss.png"/>
        <!-- <div class="ysjs">
          <img src="@/assets/img/mu-logo.png"/>
          <div class="lt theme-bgcolor">产品</div>
          <div class="rt theme-bgcolor">服务</div>
          <div class="lb theme-subbjcolor">独立</div>
          <div class="rb theme-subbjcolor">合规</div>
        </div> -->
        <div class="etitle">
          <img src="@/assets/icon/cp.png"/>
          <div class="dtitle theme-color">产品</div>
        </div>
        <div class="dtext-title">
         <div class="ti">全球范围选品及比价，为客户提供</div>
        <div>
          <div>
            <div><span class="theme-color">最好的产品</span>以满足客户投资意愿</div>
            <div>最好的报价以<span class="theme-color">最大化</span>客户收益</div>
          </div>
        </div>
      </div>
     
      <div class="etitle">
          <img src="@/assets/icon/fw.png"/>
          <div class="dtitle theme-color">服务</div>
      </div>
      <div class="dtext-title">
        <div class="ti theme-color">三方双向高效沟通及服务</div>
        <div>
          <div>
            <div>与私行打通<span class="theme-color">高效</span>畅通的沟通渠道</div>
            <div>交易时间<span class="theme-color">不设限</span></div>
          </div>
        </div>
      </div>
      <div class="etitle">
          <img src="@/assets/icon/dl.png"/>
          <div class="dtitle theme-color">独立</div>
      </div>
      <div class="dtext-title">
        <div class="ti theme-color">独立于私行</div>
        <div>
          <div>
            <div>与客户绑定，<span class="theme-color">全心</span>为客户服务</div>
            <div>为客户挑选最合适的私行，<span class="theme-color">共同服务</span>于客户</div>
          </div>
        </div>
      </div>
     
      <div class="etitle">
          <img src="@/assets/icon/hg.png"/>
          <div class="dtitle theme-color">合规</div>
      </div>
       <div class="dtext-title">
          <div class="ti theme-color">合规手续准备</div>
          <div>
            <div>
              <div>熟悉私行<span class="theme-color">各类</span>合规要求</div>
              <div>以专业机构参与，可以<span class="theme-color">节省</span>沟通成本</div>
            </div>
          </div>
       </div>
      </div>
      <!-- <img style="width:50%;margin-left:25%" src="@/assets/img/ys.png"/> -->
      
      
      <div class="finacing-footer">
        <div class="add-btn theme-bgcolor" @click="lx">联系我们</div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  methods:{
    lx(){
       window.location.href="tel:4000362988"
    }
  }
}
</script>

<style lang="scss" scoped>
.eam-box{
  margin: 4rem auto 0%;
  .fw-text{
    width: 80%;
    font-size: .8rem;
    color: #333;
    margin: 2.5% auto;
    text-align: left;
    line-height: 2;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #464646;
    line-height: 24px;
  }
  .ysjs{
    width: 150px;
    height: 150px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.04);
    border: 1px dashed #BDD1FF;
    border-radius: 50%;
    text-align: center;
    position: relative;
    margin: 2.5% auto;
    color: #fff;
    .lt{
      position: absolute;
      top:0px;
      left:0px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 50%;
    }
    .rt{
      position: absolute;
      top:0px;
      right:0px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 50%;
    }
    .lb{
      position: absolute;
      bottom:0px;
      left:0px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 50%;
    }
    .rb{
      position: absolute;
      bottom:0px;
      right:0px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 50%;
    }
    img{
      width: 70%;
      padding-top:60px;
      vertical-align: middle;

    }
  }
  .dtext-title{
    text-align: center;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #464646;
    .ti{
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 2;
    }
    // width: 80%;
    // font-size: .9rem;
    // color: #333;
    // margin: 1% auto 5%;
  }
}
.finacing-footer{
  // height: 3rem;
  // border-top:1px solid #eee;
  // background: #fff;
  padding: 2.5%;
  .add-btn{
    border-radius:8px;
    width: 100%;
    text-align: center;
    line-height: 2.5;
    color: #fff;
  }
}
// .el-divider--horizontal{
//   width: 50%;
//   margin-left: 25%;
// }
// .el-divider__text{
//   font-weight:bold;
// }
.etitle{
  text-align: center;
  img{
    width: 30px;
    margin:10% auto .5%;
  }
}
.dtitle{
  width: 80px;
  margin: 0rem auto;
  text-align: center;
  position: relative;
  // font-weight: bold;
  margin-bottom: 2.5%;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  
}
.dtitle::before{
    z-index: 3;
    position: absolute;
    top:10px;
    left: -5px;
    width: 20px;
    content: "";
    height: 1px;
    border-top:1px dashed #002D91
  }
.dtitle::after{
  top:8px;
  right: -5px;
  position: absolute;
  top:10px;
  width: 20px;
  content: "";
  height: 1px;
  border-top:1px dashed #002D91
}
.fixed-title{
  width: 100%;
  position: fixed !important;
  top:0;
}
</style>