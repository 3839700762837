var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", {
        staticClass: "fixed-title",
        attrs: { title: "EAM服务介绍", back: true },
      }),
      _c("div", { staticClass: "eam-box" }, [
        _c("div", { staticClass: "title" }, [_vm._v("服务介绍")]),
        _vm._m(0),
        _c("div", { staticClass: "title" }, [_vm._v("优势服务")]),
        _vm._m(1),
        _c("div", { staticClass: "finacing-footer" }, [
          _c(
            "div",
            { staticClass: "add-btn theme-bgcolor", on: { click: _vm.lx } },
            [_vm._v("联系我们")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-items" }, [
      _c("img", {
        staticStyle: { width: "80%", "margin-left": "10%" },
        attrs: { src: require("@/assets/img/yz.png") },
      }),
      _c("div", { staticClass: "fw-text" }, [
        _vm._v(
          "EAM能提供高度个性化和量身定制的服务，能提供非常多元化的服务，比客户更了解银行，比银行更懂客户，能够找到客户与银行沟通的痛点，架设一座通畅的桥梁。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-items" }, [
      _c("img", {
        staticStyle: { width: "50%", "margin-left": "25%" },
        attrs: { src: require("@/assets/img/yss.png") },
      }),
      _c("div", { staticClass: "etitle" }, [
        _c("img", { attrs: { src: require("@/assets/icon/cp.png") } }),
        _c("div", { staticClass: "dtitle theme-color" }, [_vm._v("产品")]),
      ]),
      _c("div", { staticClass: "dtext-title" }, [
        _c("div", { staticClass: "ti" }, [
          _vm._v("全球范围选品及比价，为客户提供"),
        ]),
        _c("div", [
          _c("div", [
            _c("div", [
              _c("span", { staticClass: "theme-color" }, [
                _vm._v("最好的产品"),
              ]),
              _vm._v("以满足客户投资意愿"),
            ]),
            _c("div", [
              _vm._v("最好的报价以"),
              _c("span", { staticClass: "theme-color" }, [_vm._v("最大化")]),
              _vm._v("客户收益"),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "etitle" }, [
        _c("img", { attrs: { src: require("@/assets/icon/fw.png") } }),
        _c("div", { staticClass: "dtitle theme-color" }, [_vm._v("服务")]),
      ]),
      _c("div", { staticClass: "dtext-title" }, [
        _c("div", { staticClass: "ti theme-color" }, [
          _vm._v("三方双向高效沟通及服务"),
        ]),
        _c("div", [
          _c("div", [
            _c("div", [
              _vm._v("与私行打通"),
              _c("span", { staticClass: "theme-color" }, [_vm._v("高效")]),
              _vm._v("畅通的沟通渠道"),
            ]),
            _c("div", [
              _vm._v("交易时间"),
              _c("span", { staticClass: "theme-color" }, [_vm._v("不设限")]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "etitle" }, [
        _c("img", { attrs: { src: require("@/assets/icon/dl.png") } }),
        _c("div", { staticClass: "dtitle theme-color" }, [_vm._v("独立")]),
      ]),
      _c("div", { staticClass: "dtext-title" }, [
        _c("div", { staticClass: "ti theme-color" }, [_vm._v("独立于私行")]),
        _c("div", [
          _c("div", [
            _c("div", [
              _vm._v("与客户绑定，"),
              _c("span", { staticClass: "theme-color" }, [_vm._v("全心")]),
              _vm._v("为客户服务"),
            ]),
            _c("div", [
              _vm._v("为客户挑选最合适的私行，"),
              _c("span", { staticClass: "theme-color" }, [_vm._v("共同服务")]),
              _vm._v("于客户"),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "etitle" }, [
        _c("img", { attrs: { src: require("@/assets/icon/hg.png") } }),
        _c("div", { staticClass: "dtitle theme-color" }, [_vm._v("合规")]),
      ]),
      _c("div", { staticClass: "dtext-title" }, [
        _c("div", { staticClass: "ti theme-color" }, [_vm._v("合规手续准备")]),
        _c("div", [
          _c("div", [
            _c("div", [
              _vm._v("熟悉私行"),
              _c("span", { staticClass: "theme-color" }, [_vm._v("各类")]),
              _vm._v("合规要求"),
            ]),
            _c("div", [
              _vm._v("以专业机构参与，可以"),
              _c("span", { staticClass: "theme-color" }, [_vm._v("节省")]),
              _vm._v("沟通成本"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }